import { Icon, IconSVGProps } from './Icon';

const IconVisible = ({ size }: IconSVGProps) => {
    return (
        <Icon size={size}>
            <svg
                role="img"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.99935 4.33333C10.526 4.33333 12.7793 5.75333 13.8793 8C12.7793 10.2467 10.526 11.6667 7.99935 11.6667C5.47268 11.6667 3.21935 10.2467 2.11935 8C3.21935 5.75333 5.47268 4.33333 7.99935 4.33333ZM7.99935 3C4.66602 3 1.81935 5.07333 0.666016 8C1.81935 10.9267 4.66602 13 7.99935 13C11.3327 13 14.1793 10.9267 15.3327 8C14.1793 5.07333 11.3327 3 7.99935 3ZM7.99935 6.33333C8.91935 6.33333 9.66602 7.08 9.66602 8C9.66602 8.92 8.91935 9.66667 7.99935 9.66667C7.07935 9.66667 6.33268 8.92 6.33268 8C6.33268 7.08 7.07935 6.33333 7.99935 6.33333ZM7.99935 5C6.34602 5 4.99935 6.34667 4.99935 8C4.99935 9.65333 6.34602 11 7.99935 11C9.65268 11 10.9993 9.65333 10.9993 8C10.9993 6.34667 9.65268 5 7.99935 5Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export { IconVisible };
