import dayjs from "dayjs";

const now = dayjs()

let filterButtonData = [
    {
        label: 'Channels',
        options: [
            { value: 'all', text: 'All Channels' },
            { value: '1', text: 'Channel one' },
            { value: '2', text: 'Channel two' }
        ]
    },
    {
        label: 'People',
        options: [
            { value: 'everyone', text: 'Everyone' },
            { value: '1', text: 'type a' },
            { value: '2', text: 'type b' }
        ]
    },
    {
        label: 'Date',
        options: [
            { value: null, text: 'All Time' },
            { value: now.subtract(1, 'day').unix() + '', text: 'Today' },
            { value: now.subtract(7, 'days').unix() + '', text: 'Last 7 days' },
            { value:  now.subtract(4, 'weeks').unix() + '', text: 'Last 4 weeks' },
            { value: now.subtract(3, 'months').unix() + '', text: 'Last 3 months' },
            { value: now.subtract(6, 'months').unix() + '', text: 'Last 6 months' },
            { value: now.subtract(12, 'months').unix() + '', text: 'Last 12 months' },
        ]
    },
    {
        label: 'Date',
        options: [
            { value: null, text: 'All Time' },
            { value:  now.subtract(4, 'weeks').unix() + '', text: 'Last 4 weeks' },
            { value: now.subtract(3, 'months').unix() + '', text: 'Last 3 months' },
            { value: now.subtract(6, 'months').unix() + '', text: 'Last 6 months' },
            { value: now.subtract(12, 'months').unix() + '', text: 'Last 12 months' },
        ]
    },
];

export default filterButtonData;
