import { Icon, IconSVGProps } from './Icon';

const IconAlert = ({ size }: IconSVGProps) => {
    return (
        <Icon size={size}>
            <svg
                role="img"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M6.99999 1.16666C3.77999 1.16666 1.16666 3.77999 1.16666 6.99999C1.16666 10.22 3.77999 12.8333 6.99999 12.8333C10.22 12.8333 12.8333 10.22 12.8333 6.99999C12.8333 3.77999 10.22 1.16666 6.99999 1.16666ZM7.58332 9.91666H6.41666V8.74999H7.58332V9.91666ZM7.58332 7.58332H6.41666V4.08332H7.58332V7.58332Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export { IconAlert };
