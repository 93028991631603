import { IconG } from '../Icon/IconG';

interface ConfidenceProps {
  responseCount: number;
}

const Confidence = ({ responseCount }: ConfidenceProps) => {
  let response = '';
  let moreDataNeeded = false;
  if (responseCount >= 80) {
    response = '95% confidence';
  } else if (responseCount >= 30 && responseCount < 79) {
    response = '90% confidence';
  } else if (responseCount < 29) {
    response = 'More data needed';
    moreDataNeeded = true;
  }

  return (
    <div className={`confidence${moreDataNeeded ? ' more-data-needed' : ''}`}>
      <p className="confidence__label">
        {moreDataNeeded && <IconG name="important" size="medium" />} {response}
      </p>
    </div>
  );
};

export { Confidence };
