import { useState, useMemo } from 'react';
// import { motion } from 'framer-motion';
import { Dialog } from '@reach/dialog';
import '@reach/dialog/styles.css';

import { Progress } from '../Progress';
import { Ditem, Dlist } from '../ListDeffinition';
import { IconG, IconClose } from '../Icon';

// Fake data
import { ApiOtherReasonsInterface } from '../../data/ApiTypes';

// Chart
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

interface TaskListDetailProps {
  title: string;
  items?: object[];
  otherModalTitle: string;
}

interface itemProps {
  id: number;
  label: string;
  otherReasons?: ApiOtherReasonsInterface[];
  number: number;
  count: number;
}

ChartJS.register(ArcElement, Tooltip, Legend);

const TaskListDetail = ({ title, items, otherModalTitle }: TaskListDetailProps) => {
  const cLabels = items.map((item: itemProps) => item.label);
  const cData = items.map((item: itemProps) => item.number);
  const bgColors = [
    '#2356f6',
    '#ED7902',
    '#AB2EFF',
    '#FDBD10',
    '#E7131A',
    '#028812',
    '#BBBBBB'
  ];

  const data = {
    labels: cLabels,
    datasets: [
      {
        label: title,
        data: cData,
        backgroundColor: bgColors,
        borderWidth: 0,
        hoverOffset: 0
      }
    ]
  };

  const options: any = {
    cutout: '70%',
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: true,
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        padding: 12,
        borderColor: '#BBBBBB',
        borderWidth: 1,
        cornerRadius: 8,
        usePointStyle: true,
        caretSize: 0
      }
    }
  };

  const [showDialog, setShowDialog] = useState(false);
  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);
  return (
    <div className="tasklist-detail">
      <h2 className="tasklist-detail__title">{title}</h2>
      <div className="tasklist-detail__list">
        <div className="tasklist-detail__chart">
          <Doughnut data={data} options={options} />
        </div>

        <div className="tasklist-detail__legend-wrap">
          {items?.map((item: itemProps, i) => {
            return (
              <span className="tasklist-detail__legend" key={'tdl-' + i}>
                <div
                  className="tasklist-detail__legend-dot"
                  style={{ backgroundColor: bgColors[i] }}></div>
                <div className="tasklist-detail__item">
                  {item.otherReasons ? (
                    <button className="tasklist-detail__button" onClick={open}>
                      {item.label}
                      <IconG name="chevron-right" size="small" />
                    </button>
                  ) : (
                    item.label
                  )}
                </div>
                {item.otherReasons && (
                  <Dialog
                    key={'reason-dialog-' + item.id}
                    aria-labelledby="dTitle"
                    isOpen={showDialog}
                    onDismiss={close}>
                    <button className="close-button" onClick={close}>
                      <span className="visually-hidden">Close</span>
                      <IconClose size="medium" />
                    </button>
                    <div className="flow">
                      <h2 id="dTitle" className="h-500-hvy">
                        {otherModalTitle}
                      </h2>
                      <Dlist>
                        {item.otherReasons.map(
                          (ireason: ApiOtherReasonsInterface, i) => {
                            return (
                              <Ditem key={'reason-' + i} title={ireason.date}>
                                {ireason.reason}
                              </Ditem>
                            );
                          }
                        )}
                      </Dlist>
                    </div>
                  </Dialog>
                )}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { TaskListDetail };
