const Avatar = () => {
  return (
    <svg
      role="img"
      aria-hidden="true"
      className="avatar-icon"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2089_149)">
        <mask
          id="mask0_2089_149"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="32"
          height="32">
          <circle cx="16" cy="16" r="15.5" fill="white" />
        </mask>
        <g mask="url(#mask0_2089_149)">
          <path
            className="avatar-icon__bg"
            d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16Z"
            fill="#ED7902"
          />
          <path
            className="avatar-icon__bg"
            d="M16 5C12.6863 5 10 7.68629 10 11C10 14.3137 12.6863 17 16 17C19.3137 17 22 14.3137 22 11C22 7.68629 19.3137 5 16 5Z"
            fill="#ED7902"
          />
          <path
            className="avatar-icon__fg"
            d="M16 5C12.6863 5 10 7.68629 10 11C10 14.3137 12.6863 17 16 17C19.3137 17 22 14.3137 22 11C22 7.68629 19.3137 5 16 5Z"
            fill="#f8c99a"
          />
          <path
            className="avatar-icon__bg"
            d="M10.1714 20C6.76304 20 4 22.763 4 26.1714C4 29.5798 6.76304 32.3429 10.1714 32.3429L22.5143 32.3429C25.9227 32.3429 28.6857 29.5798 28.6857 26.1714C28.6857 22.763 25.9227 20 22.5143 20H10.1714Z"
            fill="#ED7902"
          />
          <path
            className="avatar-icon__fg"
            d="M10.1714 20C6.76304 20 4 22.763 4 26.1714C4 29.5798 6.76304 32.3429 10.1714 32.3429L22.5143 32.3429C25.9227 32.3429 28.6857 29.5798 28.6857 26.1714C28.6857 22.763 25.9227 20 22.5143 20H10.1714Z"
            fill="#f8c99a"
          />
        </g>
      </g>
    </svg>
  );
};

export { Avatar };
