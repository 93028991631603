import { ReactNode, useState } from 'react';
import { motion } from 'framer-motion';
import bem from '../../utilities/bem';

interface TabContentProps {
    ariaId: string;
    open: boolean | string;
    children: ReactNode;
}

const spring = {
    type: 'spring',
    stiffness: 1000,
    damping: 100
};

const TabContent = ({ ariaId, open, children }: TabContentProps) => {
    return (
        <div
            id={ariaId}
            className={bem('tab__content', [open && 'open'])}
            aria-hidden={open ? 'true' : 'false'}>
            {children}
        </div>
    );
};

export { TabContent };
