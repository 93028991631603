import { ReactNode } from 'react';
import bem from '../../utilities/bem';

interface GridProps {
    children?: ReactNode | ReactNode[];
    cols?: number;
}

const Grid = ({ children, cols }: GridProps) => {
    return (
        <section className={bem('grid', cols && 'cols-' + cols)}>
            <div className="grid__container">{children}</div>
        </section>
    );
};

export { Grid };
