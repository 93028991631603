import bem from '../../utilities/bem';

interface ProgressProps {
  number: number;
  count?: number;
  label?: string;
}

const Progress = ({ number, count, label }: ProgressProps) => {
  const barWidth = number + '%';
  let level = '';
  if (number >= 80) {
    level = 'amazing';
  } else if (number >= 60 && number < 80) {
    level = 'good';
  } else if (number >= 40 && number < 60) {
    level = 'neutral';
  } else if (number >= 20 && number < 40) {
    level = 'bad';
  } else if (number < 20) {
    level = 'terrible';
  }

  const pCount = Math.round(count * (number / 100));

  return (
    <div className={bem('progress', level)} aria-label={label}>
      <div className="progress__wrap" aria-hidden="true">
        <div className="progress__bar" style={{ width: barWidth }}></div>
      </div>
      <h2 className="progress__label">
        {barWidth} {!isNaN(pCount)?(<>({pCount})</>):null}
      </h2>
    </div>
  );
};

export { Progress };
