import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Home } from './routes/Home';
import { SurveyTPI } from './routes/SurveyTPI';
import { SurveyDetail } from './routes/SurveyDetail';
import { SurveyNew } from './routes/SurveyNew';
import { Error } from './routes/Error';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { Settings } from './routes/Settings';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route index element={<Home />} />
                    <Route path="/survey/:survey_id" element={<SurveyTPI />} />
                    <Route path="/new-survey/" element={<SurveyNew />} />
                    <Route
                        path="/survey/:survey_id/task/:task_id"
                        element={<SurveyDetail />}
                    />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="*" element={<Error title="Oh No!" />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
