import React, { useState } from 'react';
import { IconClose } from '../Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { ApiTaskInterface } from '../../data/ApiTypes';

interface AddTaskProps {
    task: ApiTaskInterface;
    variants?: any;
    value?: string;
    onChange?: Function;
    onClick?: () => void;
}

// Item

const AddTask = ({
    task,
    variants,
    value,
    onChange,
    onClick
}: AddTaskProps) => {
    const [name, setName] = useState('');

    function handleChange(event) {
        setName(event.target.value);
    }

    const handleClick = () => {
        onClick();
    };

    return (
        <AnimatePresence>
            <motion.li
                className="add-task"
                key={'add-task-' + task.id}
                variants={variants}
                transition={{ ease: 'easeOut' }}>
                <label
                    className="visually-hidden"
                    htmlFor={'add-task-id-' + task.id}>
                    {task.label}
                </label>
                <input
                    id={'add-task-id-' + task.id}
                    type="text"
                    placeholder={task.placeholder}
                    value={name}
                    onChange={handleChange}
                />
                <button
                    className="add-task__remove"
                    aria-label="remove this list item"
                    onClick={handleClick}>
                    <IconClose size="medium" />
                </button>
            </motion.li>
        </AnimatePresence>
    );
};

export { AddTask };
