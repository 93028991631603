import { motion } from 'framer-motion';

const Loader = ({ loading }) => {
  return (
    <motion.div
      className="load-wrap"
      initial={{ opacity: 0 }}
      animate={loading ? { opacity: 1 } : { opacity: 0 }}
      transition={{ duration: 0.5 }}>
      <div className="bars">
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </motion.div>
  );
};

export { Loader };
