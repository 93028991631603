import { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Card } from '../components/Card';
import { Section, SectionHead } from '../components/Section';
import axios from 'axios';
// fake data
// import { getSurveys } from '../data/surveyData';
import { useProperties } from '../data/api';
// import dayjs from 'dayjs';
import { Loader } from '../components/Loader';

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.jwt}`;

const Home = () => {
  let navigate = useNavigate();

  const [FilterData, setFilterData] = useState({
    label: 'All'
  });

  //   const handleFilter = (filter) => {
  //     const filterObject = {
  //       ...filter
  //     };

  //     setFilterData(filterObject);
  //   };

  const handleCard = (id) => {
    navigate('/survey/' + id);
  };

  const { properties: surveys, isLoading } = useProperties();

  return (
    <AnimatePresence mode="wait">
      <motion.section
        className="home-dashboard"
        key="home"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3, type: 'tween' }}>
        <div className="container flow flow-m-l">
          <Section>
            <SectionHead
              title="Welcome"
              intro="Here you can view your current surveys."></SectionHead>

            <div className="u-auto-grid">
              <AnimatePresence initial={false}>
                {isLoading ? <Loader loading={surveys} /> : null}
                {surveys.length
                  ? surveys
                      .filter(
                        (survey) =>
                          survey.status === FilterData.label.toLowerCase() ||
                          FilterData.label === 'All'
                      )
                      .map((card, i) => {
                        return (
                          <Card
                            key={card.id}
                            {...card}
                            onClick={() => handleCard(card.id)}
                          />
                        );
                      })
                  : 'No results match that filter'}
              </AnimatePresence>
            </div>
          </Section>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};

export { Home };
