import bem from '../../utilities/bem';

type Size = 'small' | 'medium' | 'large';
interface IconProps {
  size?: Size;
  name?: string;
}

export interface IconSVGProps {
  size?: Size;
}

const IconG = ({ size, name }: IconProps) => {
  let iSize = '';
  if (size === 'large') {
    iSize = '32px';
  } else if (size === 'medium') {
    iSize = '24px';
  } else if (size === 'small') {
    iSize = '18px';
  }

  return (
    <svg
      className={bem('icon', name)}
      width={iSize}
      height={iSize}
      aria-hidden="true">
      <use
        href={process.env.PUBLIC_URL + `/icons/${name}.svg#icon-${name}`}></use>
    </svg>
  );
};

export { IconG };
