import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Progress } from '../Progress';

export interface TaskSummaryProps {
  id: number;
  rank: number;
  route?: string;
  title: string;
  responseCount: number;
  percentage: number;
  completionRate: number;
  satisfaction: number;
}

const TaskSummary = ({
  id,
  rank,
  title,
  route,
  responseCount,
  percentage,
  completionRate,
  satisfaction
}: TaskSummaryProps) => {
  return (
    <Link className="task-summary" to={'task/' + id}>
      <div className="task-summary__task" aria-label="Task">
        <p className="task-summary__number">{rank}</p>
        <h2 className="task-summary__title">
          <span>{title}</span>
          <span className="task-summary__sub">
            <span aria-label="repsonse count">{responseCount}</span>
            &nbsp;
            <span aria-label="percentage of total">({percentage}%)</span>
          </span>
        </h2>
      </div>
      <div className="task-summary__col">
        <Progress
          label="Completion rate"
          number={completionRate}
          
        />
      </div>
      <div className="task-summary__col">
        <Progress
          label="Customer Satisfaction"
          number={satisfaction}
          
        />
      </div>
    </Link>
  );
};

export { TaskSummary };
