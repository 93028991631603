import { ReactNode } from 'react';

interface DitemProps {
    title?: string;
    link?: string;
    children: string | string[] | ReactNode;
}

const Ditem = ({ title, link, children }: DitemProps) => {
    return (
        <div className="dlist__item">
            {title && (
                <dt className="dlist__dt">
                    {link ? <a href={link}>{title}</a> : title}
                </dt>
            )}
            <dd className="dlist__dd">{children}</dd>
        </div>
    );
};

export { Ditem };
