import { useState } from 'react';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Tabs, Tab, TabContent } from '../components/Tabs';
import { Section, SectionHead } from '../components/Section';
import { AnimatePresence, motion } from 'framer-motion';
import { IconList, IconPaint, IconWallet } from '../components/Icon';

const Settings = () => {
    const [tabData, setTabData] = useState({
        label: 'Channels'
    });

    const handleTab = (tab) => {
        const tabObject = {
            ...tab,
            open: true
        };
        setTabData(tabObject);
    };

    const crumb = [
        {
            route: '/settings',
            title: 'Settings'
        }
    ];

    return (
        <AnimatePresence mode="wait">
            <motion.section
                className="survey-dashboard"
                key="settings"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, type: 'tween' }}>
                <div className="container flow flow-m-l">
                    <Breadcrumbs crumbs={crumb} />
                    <Section>
                        <SectionHead title="Settings"></SectionHead>
                        <Tabs>
                            <Tab
                                label="Channels"
                                ariaRef="cTc"
                                open={'Channels' === tabData.label}
                                onClick={(data) => handleTab(data)}>
                                <IconList size="small" />
                            </Tab>
                            <Tab
                                label="Brand identity"
                                ariaRef="biTc"
                                open={'Brand identity' === tabData.label}
                                onClick={(data) => handleTab(data)}>
                                <IconPaint size="small" />
                            </Tab>
                            <Tab
                                label="Billing"
                                ariaRef="bTc"
                                open={'Billing' === tabData.label}
                                onClick={(data) => handleTab(data)}>
                                <IconWallet size="small" />
                            </Tab>
                        </Tabs>
                        <TabContent
                            ariaId="cTc"
                            open={'Channels' === tabData?.label || !tabData}>
                            <div className="flex-row">
                                <p className="text-verticle">
                                    参ろけなぼ面於連ごフレ灯謙善ゃげよ図厚ば件開の如8国覧ニメ坂発イオロヌ権行スねご医講ニキエ麩浩べいンぎ協権ス渡理ソヲ民
                                </p>
                                <p className="text-verticle">
                                    便メモ景開ヌテシミ朱断社クれ
                                </p>
                                <p className="text-verticle">
                                    細減セヌル全案惑表ばてスげ情投ほはレク授情ケルセ顔辞ヤ混
                                </p>
                                <p className="text-verticle">
                                    台ホ才載情びや中成び化覚理ソナクサ解考リラチニ区弾女の
                                </p>
                            </div>
                        </TabContent>
                        <TabContent
                            ariaId="biTc"
                            open={
                                'Brand identity' === tabData?.label || !tabData
                            }>
                            <div className="flex-row">
                                <p className="text-verticle">
                                    将ぎざぐま表属京ヘ式様あ賃紙ねくつし前合ンがぱね法湊いづ議名タヘラ変権もろみ然9択ク容速入3個オネ訃法じみち則締ハチ常業ぶら備妊じ
                                </p>
                                <p className="text-verticle">
                                    広いみ量然へしー碑庁リ時決伎ミタ権可ドびぱた法97一いぞト是訴断サオシツ劇契チレ校肺ソ看差職る宅約けね試女げれぎ無屋ぜ
                                </p>
                                <p className="text-verticle">
                                    4陸シラニ魚間ル取陽キユルリ正今ク安芸ソケ情40子ちきで爆健全格ば芸朝ソ備昨文惑だっ。
                                </p>
                            </div>
                        </TabContent>
                        <TabContent
                            ariaId="bTc"
                            open={'Billing' === tabData?.label || !tabData}>
                            <div className="flex-row">
                                <p className="text-verticle">
                                    久大フ京転トホケネ和5話常ヒクツ下間ネヲタモ価金すつッ慮近オ物茨得続し子勝ルレ視能左月52賠予現やと。
                                </p>
                                <p className="text-verticle">
                                    実エモコ相咲ょンおフ索所ほ惑止べみをぴ藤相ぴてべぼ移数ナイコ井短に予春ゃりッ会月政リスに非硬とら品囲ゅか退友くき。
                                </p>
                                <p className="text-verticle">
                                    住おりし風掲げが方練ケリ中17騒ゆぴじぐ立議ユ無能ス足抵い者兵エ新購マエヤリ界調ぼろる低無ヌツホヱ住覧トツ切故口将格じゅー。
                                </p>
                            </div>
                        </TabContent>
                    </Section>
                </div>
            </motion.section>
        </AnimatePresence>
    );
};

export { Settings };
