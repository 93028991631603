import { useParams } from 'react-router-dom';
import { Section, SectionHead } from '../components/Section';
import { AnimatePresence, motion } from 'framer-motion';
import { Select } from '../components/Select';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Grid, GridCol } from '../components/Grid';
import { TaskListDetail } from '../components/TaskListDetail';
import { TaskMetric } from '../components/TaskMetric/TaskMetric';
import { Line } from 'react-chartjs-2';

// fake data
import { TaskInterface } from '../data/ApiTypes';

// Chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
} from 'chart.js';
import {
  useProperties,
  useProperty,
  useSegments,
  useTask,
  useChanges
} from '../data/api';
import { useContext, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import filterButtonData from '../data/filterButtonData';
import AppContext from '../hooks/appContext';
import { Loader } from '../components/Loader';
import { Confidence } from '../components/Confidence';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const config = {
  maintainAspectRatio: false,
  elements: {
    line: {
      tension: 0.2
    },
    point: {
      pointStyle: 'circle',
      radius: 0,
      hitRadius: 12
    }
  },
  scales: {
    x: {
      color: '#BBBBBB',
      grid: {
        tickColor: '#FFFFFF'
      },
      ticks: {
        maxTicksLimit: 2,
        maxRotation: 0,
        align: 'start' as const
      }
    },
    y1: {
      position: 'right' as const,
      color: '#BBBBBB',
      grid: {
        drawOnChartArea: false,
        tickColor: '#FFFFFF'
      },
      ticks: {
        display: false
      }
    },
    y: {
      color: '#BBBBBB',
      offset: true,
      grid: {
        color: '#ECECEC',
        borderDash: [2, 2],
        tickColor: '#FFFFFF',
        offset: true
      },
      beginAtZero: false,
      min: -1,
      max: 100,
      ticks: {
        display: false,
        stepSize: 25,
        autoSkip: false
      }
    }
  },
  intersect: 'nearest',
  mode: 'index',
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true,
      backgroundColor: '#fff',
      titleColor: '#000',
      titleFont: {
        size: 18,
        weight: '600',
        family: 'SuisseIntl',
        lineHeight: 1.25
      },
      titleMarginBottom: 10,
      bodyFont: {
        size: 14,
        weight: '400',
        family: 'SuisseIntl',
        lineHeight: 1.25
      },
      boxWidth: 12,
      boxHeight: 12,
      boxPadding: 8,
      bodySpacing: 8,
      bodyColor: '#000',
      padding: 12,
      borderColor: '#BBBBBB',
      borderWidth: 1,
      cornerRadius: 8,
      usePointStyle: true,
      caretSize: 0,
      interaction: {
        mode: 'index',
        axis: 'x'
      },
      callbacks: {
        label: function (tooltipitem) {
          return tooltipitem['raw'] + '%  ' + tooltipitem['dataset']['label'];
        }
      }
    }
  }
};

const SurveyDetail = () => {
  const { survey_id, task_id } = useParams();
  const dateFilters =  filterButtonData[3].options
  const [filterDate, setFilterDate] = useState(
   dateFilters.find(df => df.value == sessionStorage.dateFilter)?.value || dateFilters[1].value
  );
  const { properties: surveys, isLoading: propertiesLoading } = useProperties();
  const selectedSurvey = surveys.find((s) => s.id.toString() === survey_id);
  const { tasks, isLoading: tasksLoading } = useProperty(survey_id);
  const { segments, isLoading: segmentsLoading } = useSegments(survey_id);
  const [segment, setSegment] = useState('');

  useEffect(() => {
    if(filterDate)
      sessionStorage.dateFilter = filterDate;
  }, [filterDate])

  const { data: task, isLoading: taskLoading } = useTask(
    survey_id,
    task_id,
    filterDate,
    segment
  );

  const { data: changes, isLoading: changesLoading } = useChanges(
    survey_id,
    task_id,
    filterDate,
    segment
  );
  let endCompPercent,
    startCompPercent,
    compPercentChange,
    endSatPercent,
    startSatPercent,
    satPercentChange;
  if (changes && changes.startStats && changes.endStats) {
    const { startStats, endStats } = changes;
    endCompPercent = endStats.win / (endStats.win + endStats.loss);
    startCompPercent = startStats.win / (startStats.win + startStats.loss);
    compPercentChange = endCompPercent - startCompPercent;
    endSatPercent = endStats.happy / (endStats.happy + endStats.sad);
    startSatPercent = startStats.happy / (startStats.happy + startStats.sad);
    satPercentChange = endSatPercent - startSatPercent;
  }

  console.log(task);

  // Total resposes number
  const taskCount = tasks?.filter((task) => {
    return task.id === task_id;
  });

  // filter date string for TaskMetric
  const filterValue = filterButtonData[2].options.find(
    (f) => f.value === filterDate
  );
  const taskMetricDate = filterValue.text.toLowerCase();
  let taskMetricDateText = '';
  if (taskMetricDate === 'all time') {
    taskMetricDateText = 'for ' + taskMetricDate;
  } else if (taskMetricDate === 'today') {
    taskMetricDateText = 'as of ' + taskMetricDate;
  } else {
    taskMetricDateText = 'in the ' + taskMetricDate;
  }

  const compData: any = useMemo(() => {
    if (!task?.graphs) return;
    const relevantComp = task.graphs.filter(
      (g) => parseInt(g.win) + parseInt(g.loss)
    );
    const relevantCsat = task.graphs.filter(
      (g) => parseInt(g.happy) + parseInt(g.sad)
    );
    const labels = [];
    const data = { comp: [], csat: [] };

    if (relevantComp.length === 0 && relevantCsat.length === 0) return;
    // find lowest date, using dayjs, from relevantComp and relevantCsat
    const lowestDate = dayjs(relevantComp[0].day).isBefore(
      dayjs(relevantCsat[0].day)
    )
      ? dayjs(relevantComp[0].day)
      : dayjs(relevantCsat[0].day);
    // Store last values
    let lastComp = 0,
      lastCsat = 0;
    // for each day between lowestDate and today, add a label and data point
    for (let i = 0; i < dayjs().diff(lowestDate, 'day'); i++) {
      const date = lowestDate.add(i, 'day');
      labels.push(date.format('D MMMM YYYY'));
      const comp = relevantComp.find((g) => dayjs(g.day).isSame(date, 'day'));
      const csat = relevantCsat.find((g) => dayjs(g.day).isSame(date, 'day'));
      // if it is missing or zero, we should use yesterday score TODO
      if (comp) {
        let score =
          (parseInt(comp.win) / (parseInt(comp.win) + parseInt(comp.loss))) *
          100;
        lastComp = score || lastComp;
      }
      data.comp.push(Math.round(lastComp));
      if (csat) {
        let score =
          (parseInt(csat.happy) / (parseInt(csat.happy) + parseInt(csat.sad))) *
          100;
        lastCsat = score || lastCsat;
      }
      data.csat.push(Math.round(lastCsat));
    }
    return {
      labels,
      datasets: [
        {
          fill: true,
          label: 'Task completion',
          data: data.comp,
          borderWidth: 2,
          borderColor: '#2356F6',
          backgroundColor: 'transparent',
          pointBackgroundColor: '#2356F6'
        },
        {
          fill: true,
          label: 'Customer satisfation',
          data: data.csat,
          borderWidth: 2,
          borderColor: '#35A041',
          backgroundColor: 'transparent',
          pointBackgroundColor: '#35A041'
        }
      ]
    };
  }, [task]);

  const percents = useMemo(() => {
    if (!(task?.graphs && task.average))
      return { completion: 0, cSat: 0, aveComp: 0, aveCSat: 0 };
    const latest = task.graphs[task.graphs.length - 1];
    return {
      completion:
        Math.round(
          (parseInt(latest.win) /
            (parseInt(latest.loss) + parseInt(latest.win))) *
            100
        ) || 0,
      cSat:
        Math.round(
          (parseInt(latest.happy) /
            (parseInt(latest.sad) + parseInt(latest.happy))) *
            100
        ) || 0,
      aveComp:
        Math.round(
          (parseInt(task.average.win) /
            (parseInt(task.average.loss) + parseInt(task.average.win))) *
            100
        ) || 0,
      aveCSat:
        Math.round(
          (parseInt(task.average.happy) /
            (parseInt(task.average.sad) + parseInt(task.average.happy))) *
            100
        ) || 0
    };
  }, [task]);

  // Todo: refactor the below correctly
  const typedTasks = tasks as TaskInterface[];
  const selectedTask = typedTasks.find((t) => t.id.toString() === task_id);

  const crumb = useMemo(() => {
    if (!selectedTask || !selectedSurvey) return [];
    return [
      {
        route: '/survey/' + selectedSurvey.id,
        title: selectedSurvey.title
      },
      {
        route: '/survey/' + selectedSurvey.id + '/task/' + selectedTask.id,
        title: selectedTask.title
      }
    ];
  }, [selectedTask, selectedSurvey]);

  const reasonsTotal =
    task?.reasons?.reduce((t, r) => t + parseInt(r.count), 0) +
    (task?.whyOther?.length || 0);
  const reasons = task?.reasons?.map((r) => ({
    label: r.title,
    number: Math.round((parseInt(r.count) / reasonsTotal) * 100),
    count: reasonsTotal
  }));
  if (task?.whyOther?.length) {
    reasons.push({
      label: 'Other',
      number: Math.round(
        (parseInt(task?.whyOther?.length) / reasonsTotal) * 100
      ),
      count: task?.whyOther?.length,
      otherReasons: task.whyOther.map((other) => ({
        date: dayjs(other.date).format('ddd D MMM YYYY'),
        reason: other.title
      }))
    });
  }

  const nextTotal =
    task?.next?.reduce((t, r) => t + parseInt(r.count), 0) +
    (task?.nextOther?.length || 0);
  const next = task?.next?.map((r) => ({
    label: r.title,
    number: Math.round((parseInt(r.count) / nextTotal) * 100),
    count: nextTotal
  }));
  if (task?.nextOther?.length) {
    next.push({
      label: 'Other',
      number: Math.round((parseInt(task?.nextOther?.length) / nextTotal) * 100),
      count: task?.nextOther?.length,
      otherReasons: task.nextOther.map((other) => ({
        date: dayjs(other.date).format('ddd D MMM YYYY'),
        reason: other.title
      }))
    });
  }

  useEffect(() => {
    document.title = selectedTask?.title + ' - Intentful';
  }, [selectedTask]);

  return (
    <AnimatePresence mode="wait">
      <motion.section
        className="survey-dashboard"
        key="survey-detail"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3, type: 'tween' }}>
        <div className="container flow flow-m-l">
          <Breadcrumbs crumbs={crumb} />
          <Section>
            <SectionHead title={selectedTask?.title}>
              <p className="text-right flow flow-3xs">
                <span className="sh-300-md mono-grey">Total responses</span>
                <br />
                <span className="h-500-md">
                  {taskCount[0]?.responseCount.toLocaleString()}
                </span>
              </p>
            </SectionHead>
            <div className="flex-row">
              {/* <Select label="Channels" options={channelItems} /> */}
              <Select
                label="People"
                options={segments}
                onValueChange={setSegment}
              />
              <div className="flex-end">
                <Select
                  label="Date"
                  options={dateFilters}
                  onValueChange={setFilterDate}
                  value={filterDate}
                />
              </div>
            </div>

            <Loader
              loading={tasksLoading || propertiesLoading || taskLoading}
            />

            {!(tasksLoading || propertiesLoading || taskLoading) && (
              <>
                <div className="panel flow flow-2xs">
                  <Grid>
                    <GridCol md={{ from: 1, to: -1 }}>
                      <div className="flex-row space-auto wrap-sm">
                        <TaskMetric
                          title="Task completion"
                          percent={percents.aveComp}
                          calcPercent={Math.floor(
                            Math.abs(compPercentChange * 100)
                          )}
                          percentUp={compPercentChange > 0}
                          dateRange={taskMetricDateText}
                        />

                        <TaskMetric
                          title="Customer satisfation"
                          percent={percents.aveCSat}
                          calcPercent={Math.floor(
                            Math.abs(satPercentChange * 100)
                          )}
                          percentUp={satPercentChange > 0}
                          dateRange={taskMetricDateText}
                          color="green"
                        />

                        <div className="flex-col end">
                          <Confidence responseCount={changes?.totalCount>400?95:0} />
                          <a href="#" className="text-link p-400-rg">
                            How are these scores calculated?
                          </a>
                        </div>
                      </div>
                    </GridCol>
                    <GridCol md={{ from: 1, to: -1 }}>
                      {compData && (
                        <>
                          <Line height={200} options={config} data={compData} />
                          <span className="today">Today</span>
                        </>
                      )}
                    </GridCol>
                  </Grid>
                </div>

                <Grid>
                  {reasons.length > 0 && (
                    <GridCol md={{ from: 1, to: 7 }}>
                      <TaskListDetail
                        title="Reasons why they failed"
                        items={reasons}
                        otherModalTitle='Other reasons why they failed'
                      />
                    </GridCol>
                  )}
                  {next.length > 0 && (
                    <GridCol md={{ from: 7, to: -1 }}>
                      <TaskListDetail
                        title="What will they do next?"
                        otherModalTitle='What will they do next?'
                        items={next}
                      />
                    </GridCol>
                  )}
                </Grid>
              </>
            )}
          </Section>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};

export { SurveyDetail };
