import useSWR from 'swr'
import dayjs from "dayjs"
import { createClient } from "@supabase/supabase-js"

export const supabase = createClient(
    process.env.REACT_APP_SUPABASE_URL,
    process.env.REACT_APP_SUPABASE_KEY
)

const get = (url) => {
    return supabase.auth.getSession().then(({data, error}) => {
        return fetch(url, {
            headers: {
                Authorization: `Bearer ${data?.session?.access_token}`
            }
        }).then(res => res.json())
    })
}

const swrConfig = {
    refreshInterval: 300 * 1000,
}

function useProperty(property, from, segment){
    let params = []
    if(from)
        params.push(`from=${from}`)
    if(segment)
        params.push(`segment=${segment}`)
    const query = params.length ? `?${params.join('&')}` : ''
    const { data, error, isLoading } = useSWR(
        process.env.REACT_APP_API_URL + `/property/${property}${query}`,
        get,
        swrConfig
    )

    // add fields to data - store in a new variable
    let proc = data?.map(d => ({
        ...d,
        id: d.trying_to,
        responseCount: parseInt(d.win) + parseInt(d.loss),
        completionRate: Math.round(parseInt(d.win) / (parseInt(d.win) + parseInt(d.loss)) * 100) || 0,
        satisfaction: Math.round(parseInt(d.happy) / (parseInt(d.happy) + parseInt(d.sad)) * 100) || 0
    })) || []
    // Sort proc by responseCount
    proc.sort((a, b) => {
        return b.responseCount - a.responseCount
    })
    // Add rank to proc
    proc = proc.map((p, i) => {
        return {
            ...p,
            rank: i + 1
        }
    })
    return {
      tasks: proc,
      isLoading,
      isError: error
    }
}

function useSegments(property){
    const { data, error, isLoading } = useSWR(
        process.env.REACT_APP_API_URL + `/property/${property}/segments`,
        get,
        swrConfig
    )
    const segments = [{ value: '', text: 'Everyone' }]
    if(data)
        data.forEach(d => {
            segments.push({
                value: d.id,
                text: d.title
            })
        })
    return {
        segments,
        isLoading,
        isError: error
    }
}

function useProperties(){
    const { data, error, isLoading } = useSWR(
        process.env.REACT_APP_API_URL + `/property/list`,
        get,
        swrConfig
    )
 
    return {
      properties: data?.map(pro => {
            return {
                id: pro.id,
                title: pro.title,
                description:
                    'In vitae maximus ipsum. Nullam in ligulased tortor tincidunt accumsan Fusce molestie maximus orci.',
                overline: 'Task Performance Indicator',
                status: 'active',
                created: 'Created ' + dayjs(pro.created_at).format('DD MMM YYYY')
            }
        }) || [],
        isLoading,
        isError: error
    }
}

function useTask(property, task, from, segment){
    const params = []
    if(from)
        params.push(`from=${from}`)
    if(segment)
        params.push(`segment=${segment}`)
    const query = params.length ? `?${params.join('&')}` : ''
    const { data, error, isLoading } = useSWR(
        process.env.REACT_APP_API_URL + `/property/${property}/${task}${query}`,
        get,
        swrConfig
    )
    return {
      data: data,
      isLoading,
      isError: error
    }
}

// From is a unix timestamp
function useChanges(property, task, from, segment){
    const formatDate = dayjs(from * 1000).format('YYYY-MM-DD')
    const params = []
    if(formatDate)
        params.push(`date=${formatDate}`)
    if(segment)
        params.push(`segment=${segment}`)
    const query = params.length ? `?${params.join('&')}` : ''
    const { data, error, isLoading } = useSWR(
        process.env.REACT_APP_API_URL + `/property/${property}/${task}/changes${query}`,
        get,
        swrConfig
    )
    return {
      data: data,
      isLoading,
      isError: error
    }
}

export {
    useProperty,
    useProperties,
    useTask,
    useSegments,
    useChanges
}