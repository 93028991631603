import { ReactNode } from 'react';

interface DlistProps {
    children: ReactNode;
}

const Dlist = ({ children }: DlistProps) => {
    return <dl className="dlist">{children}</dl>;
};

export { Dlist };
