import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import bem from '../../utilities/bem';

type Shape = 'default' | 'circle' | 'pill';
type Size = 'small' | 'medium' | 'large' | 'max';
type Color = 'primary' | 'secondary';
interface ButtonProps {
  shape?: Shape;
  size?: Size;
  color?: Color;
  onClick?: () => void;
  children?: ReactNode;
}

const Button = ({ shape, size, color, onClick, children }: ButtonProps) => {
  const handleClick = () => {
    onClick();
  };

  const spring = {
    type: 'spring',
    stiffness: 1000,
    damping: 100
  };

  return (
    <motion.button
      transition={spring}
      className={bem('button', [shape, size, color])}
      onClick={handleClick}>
      {children}
    </motion.button>
  );
};

export { Button };
