import { useState } from 'react';
import { IconG } from '../Icon';

type TaskMetricColor = 'blue' | 'green';

interface TaskMetricProps {
  title: string;
  percent: number;
  dateRange: string;
  calcPercent: number;
  percentUp?: boolean;
  color?: TaskMetricColor;
}

const TaskMetric = ({
  title,
  percent,
  calcPercent,
  percentUp,
  dateRange,
  color
}: TaskMetricProps) => {
  const barLength = percent;
  return (
    <div className={`task-metric ${color ? color : ''}`}>
      <div className="task-metric__bar">
        <span>
          {isNaN(percent)?0:percent}
          <span>%</span>
        </span>
        <svg
          role="img"
          aria-hidden="true"
          width="128"
          height="128"
          viewBox="0 0 128 128"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M101.487 112.254C115.788 101.156 125.035 83.8394 125.149 64.333C125.346 30.6028 98.1628 3.099 64.4326 2.90152C30.7024 2.70404 3.19855 29.8877 3.00107 63.6179C2.88536 83.3815 12.17 101.007 26.664 112.255"
            stroke="#D9D9D9"
            pathLength={100}
            strokeWidth="1"
            strokeLinecap="round"
            fill="none"
          />
          <path
            className="task-metric__bar-path"
            style={{ strokeDashoffset: (100 - barLength) * -1  }}
            d="M101.487 112.254C115.788 101.156 125.035 83.8394 125.149 64.333C125.346 30.6028 98.1628 3.099 64.4326 2.90152C30.7024 2.70404 3.19855 29.8877 3.00107 63.6179C2.88536 83.3815 12.17 101.007 26.664 112.255"
            pathLength={100}
            strokeWidth="6"
            strokeLinecap="round"
            fill="none"
          />
        </svg>
      </div>
      <div className="task-metric__labels">
        <h2 className="p-700-hvy">{title}</h2>
        {!isNaN(calcPercent) && (<p className={`task-metric__sub p-400-rg ${percentUp && 'up'}`}>
          {percentUp ? (
            <IconG name="sort-up" size="small" />
          ) : (
            <IconG name="sort-down" size="small" />
          )}
          <span className="p-400-hvy">{calcPercent}%&nbsp;</span>
          {dateRange}
        </p>)}
      </div>
    </div>
  );
};

export { TaskMetric };
