import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface HeadProps {
    children?: ReactNode;
}

const Header = ({ children }: HeadProps) => {
    return (
        <header className="header">
            <div className="container flex-center">
                <Link to="/">
                    <svg
                        role="img"
                        className="header__logo"
                        aria-label="Intentful logo mark"
                        width="96"
                        height="48"
                        viewBox="0 0 96 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M16.0188 39.0094C16.0188 43.4329 12.4329 47.0188 8.0094 47.0188C3.58593 47.0188 0 43.4329 0 39.0094C0 34.5859 3.58593 31 8.0094 31C12.4329 31 16.0188 34.5859 16.0188 39.0094Z"
                            fill="#2356F6"
                        />
                        <path
                            d="M16.0188 39.0094C16.0188 34.5859 19.6048 31 24.0283 31H72.0283C76.4517 31 80.0377 34.5859 80.0377 39.0094C80.0377 43.4329 76.4517 47.0188 72.0283 47.0188H24.0283C19.6048 47.0188 16.0188 43.4329 16.0188 39.0094Z"
                            fill="#2356F6"
                        />
                        <path
                            d="M0 15.5C0 6.93959 6.93959 0 15.5 0H80.5C89.0604 0 96 6.93959 96 15.5C96 24.0604 89.0604 31 80.5 31H15.5C6.93958 31 0 24.0604 0 15.5Z"
                            fill="#2356F6"
                        />
                        <path
                            d="M20.1632 21H17.9885V9.97426H20.1632V21Z"
                            fill="white"
                        />
                        <path
                            d="M24.45 13.8979C24.6832 13.4822 25.0076 13.1679 25.4233 12.955C25.8491 12.7421 26.3155 12.6356 26.8224 12.6356C27.8059 12.6356 28.5207 12.9195 28.9668 13.4873C29.423 14.0449 29.6511 14.8307 29.6511 15.8445V21H27.5524V16.3008C27.5524 16.0372 27.5321 15.7888 27.4916 15.5556C27.4612 15.3122 27.3953 15.0993 27.2939 14.9168C27.1925 14.7343 27.0455 14.5924 26.8529 14.491C26.6704 14.3795 26.4321 14.3237 26.1381 14.3237C25.7934 14.3237 25.5095 14.3896 25.2864 14.5214C25.0735 14.6532 24.9012 14.8307 24.7694 15.0537C24.6477 15.2666 24.5615 15.5201 24.5108 15.8141C24.4703 16.1081 24.45 16.4173 24.45 16.7418V21H22.3513V12.8181H24.45V13.8979Z"
                            fill="white"
                        />
                        <path
                            d="M31.9497 10.5978H34.0484V12.8181H35.7516V14.2629H34.0484V18.4907C34.0484 18.9064 34.1244 19.1801 34.2765 19.3119C34.4387 19.4336 34.7074 19.4944 35.0825 19.4944H35.7212V21H34.1852C33.7189 21 33.3387 20.9645 33.0446 20.8935C32.7506 20.8124 32.5225 20.6857 32.3603 20.5133C32.1981 20.3309 32.0865 20.1027 32.0257 19.829C31.975 19.5451 31.9497 19.2004 31.9497 18.7949V14.2629H30.7939V12.8181H31.9497V10.5978Z"
                            fill="white"
                        />
                        <path
                            d="M40.9354 21.1977C40.2663 21.1977 39.6732 21.0862 39.1561 20.8631C38.6492 20.6299 38.2183 20.3156 37.8634 19.9202C37.5187 19.5248 37.2551 19.0686 37.0726 18.5515C36.9003 18.0243 36.8141 17.4667 36.8141 16.8787C36.8141 16.2703 36.9104 15.7076 37.103 15.1906C37.2957 14.6735 37.5694 14.2274 37.9243 13.8523C38.2791 13.467 38.7049 13.1679 39.2017 12.955C39.6985 12.7421 40.2561 12.6356 40.8746 12.6356C41.5133 12.6356 42.0811 12.7522 42.5779 12.9854C43.0848 13.2186 43.5106 13.5481 43.8553 13.9739C44.2001 14.3998 44.4535 14.9118 44.6157 15.5099C44.7881 16.1081 44.8641 16.7671 44.8439 17.487H38.9128C38.9331 18.0953 39.1257 18.5972 39.4907 18.9926C39.8557 19.3778 40.3322 19.5705 40.9202 19.5705C41.2649 19.5705 41.5742 19.5046 41.8479 19.3728C42.1318 19.2308 42.3802 18.9824 42.5931 18.6276H44.7526C44.6411 18.9317 44.4839 19.2359 44.2812 19.54C44.0885 19.8442 43.8351 20.123 43.5208 20.3765C43.2166 20.6198 42.8516 20.8175 42.4258 20.9696C42 21.1217 41.5032 21.1977 40.9354 21.1977ZM40.829 14.1716C40.6161 14.1716 40.4031 14.2122 40.1902 14.2933C39.9773 14.3643 39.7796 14.4809 39.5971 14.6431C39.4248 14.7952 39.2778 14.9878 39.1561 15.221C39.0344 15.4542 38.9584 15.7279 38.928 16.0422H42.7148C42.7046 15.6975 42.6438 15.4086 42.5323 15.1754C42.4207 14.932 42.2737 14.7394 42.0912 14.5975C41.9189 14.4454 41.7212 14.3389 41.4981 14.2781C41.2852 14.2071 41.0622 14.1716 40.829 14.1716Z"
                            fill="white"
                        />
                        <path
                            d="M48.5465 13.8979C48.7797 13.4822 49.1042 13.1679 49.5198 12.955C49.9457 12.7421 50.412 12.6356 50.919 12.6356C51.9024 12.6356 52.6172 12.9195 53.0633 13.4873C53.5195 14.0449 53.7477 14.8307 53.7477 15.8445V21H51.649V16.3008C51.649 16.0372 51.6287 15.7888 51.5881 15.5556C51.5577 15.3122 51.4918 15.0993 51.3904 14.9168C51.289 14.7343 51.142 14.5924 50.9494 14.491C50.7669 14.3795 50.5286 14.3237 50.2346 14.3237C49.8899 14.3237 49.606 14.3896 49.383 14.5214C49.1701 14.6532 48.9977 14.8307 48.8659 15.0537C48.7442 15.2666 48.6581 15.5201 48.6074 15.8141C48.5668 16.1081 48.5465 16.4173 48.5465 16.7418V21H46.4478V12.8181H48.5465V13.8979Z"
                            fill="white"
                        />
                        <path
                            d="M56.0462 10.5978H58.1449V12.8181H59.8482V14.2629H58.1449V18.4907C58.1449 18.9064 58.2209 19.1801 58.373 19.3119C58.5352 19.4336 58.8039 19.4944 59.179 19.4944H59.8178V21H58.2818C57.8154 21 57.4352 20.9645 57.1412 20.8935C56.8472 20.8124 56.619 20.6857 56.4568 20.5133C56.2946 20.3309 56.1831 20.1027 56.1222 19.829C56.0715 19.5451 56.0462 19.2004 56.0462 18.7949V14.2629H54.8904V12.8181H56.0462V10.5978Z"
                            fill="white"
                        />
                        <path
                            d="M61.6406 12.3619C61.6406 11.8448 61.7014 11.4292 61.8231 11.1149C61.9448 10.8006 62.1323 10.5623 62.3858 10.4001C62.6393 10.2277 62.9535 10.1162 63.3287 10.0655C63.7038 10.0047 64.1499 9.97426 64.667 9.97426H65.3817V11.5255H64.9711C64.7075 11.5255 64.4946 11.5356 64.3324 11.5559C64.1803 11.5762 64.0587 11.6218 63.9674 11.6928C63.8762 11.7536 63.8153 11.8448 63.7849 11.9665C63.7545 12.078 63.7393 12.2301 63.7393 12.4227V12.8181H65.3817V14.2629H63.7393V21H61.6406V14.2629H60.4848V12.8181H61.6406V12.3619Z"
                            fill="white"
                        />
                        <path
                            d="M71.7317 19.9202C71.4985 20.3359 71.169 20.6553 70.7431 20.8783C70.3173 21.0913 69.851 21.1977 69.344 21.1977C68.3606 21.1977 67.6559 20.9138 67.2301 20.3461C66.8043 19.7783 66.5914 18.9926 66.5914 17.9888V12.8181H68.6901V17.5326C68.6901 17.8063 68.7053 18.0649 68.7357 18.3082C68.7661 18.5414 68.827 18.7492 68.9182 18.9317C69.0094 19.1142 69.1412 19.2562 69.3136 19.3575C69.4961 19.4589 69.7344 19.5096 70.0284 19.5096C70.3731 19.5096 70.657 19.4437 70.88 19.3119C71.1031 19.1801 71.2754 19.0027 71.3971 18.7796C71.5289 18.5566 71.6151 18.2981 71.6556 18.004C71.7063 17.71 71.7317 17.4008 71.7317 17.0764V12.8181H73.8304V21H71.7317V19.9202Z"
                            fill="white"
                        />
                        <path
                            d="M78.0614 21H75.9627V9.97426H78.0614V21Z"
                            fill="white"
                        />
                    </svg>
                </Link>
                <div className="header__utility">{children}</div>
            </div>
        </header>
    );
};

export { Header };
