import { IconG } from '../Icon';

interface LoginProps {
  title: string;
  success?: string;
  intro: string;
  children: React.ReactNode;
}

const Login = ({ title, success, intro, children }: LoginProps) => {
  return (
    <div className="login-screen">
      <div className="login-screen__inner flow flow-s">
        <h2 className="login-screen__title">{title}</h2>
        {success && (
          <p className="login-screen__success">
            <span>
              <IconG name="check-circle" size="small" />
            </span>
            <span>{success}</span>
          </p>
        )}
        <p className="login-screen__intro">{intro}</p>
        <div className="login-screen__inputs flow-xl">{children}</div>
      </div>
      <div className="login-screen__pattern">
        <svg
          className="intentful-pattern"
          role="img"
          aria-hidden="true"
          preserveAspectRatio="xMinYMin slice"
          width="1402"
          height="753"
          viewBox="0 0 1402 753"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <rect
            className="rect"
            x="84.3867"
            y="346.548"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 84.3867 346.548)"
            fill="#FDBD10"
          />
          <rect
            className="rect"
            x="406.453"
            y="346.548"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 406.453 346.548)"
            fill="#FDBD10"
          />
          <rect
            className="rect"
            x="567.48"
            y="24"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 567.48 24)"
            fill="#AB2EFF"
          />
          <rect
            className="rect"
            x="164.902"
            y="24"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 164.902 24)"
            fill="#2356F6"
          />
          <rect
            className="rect"
            x="246.387"
            y="24"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 246.387 24)"
            fill="#EC1C24"
          />
          <rect
            className="rect"
            x="164.902"
            y="346.548"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 164.902 346.548)"
            fill="#EC1C24"
          />
          <rect
            className="rect"
            x="84.3867"
            y="668.613"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 84.3867 668.613)"
            fill="#2356F6"
          />
          <rect
            className="rect"
            x="406.453"
            y="668.613"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 406.453 668.613)"
            fill="#ED7902"
          />
          <rect
            className="rect"
            x="245.418"
            y="588.096"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 245.418 588.096)"
            fill="#FDBD10"
          />
          <rect
            className="rect"
            x="325.934"
            y="427.064"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 325.934 427.064)"
            fill="#AB2EFF"
          />
          <rect
            className="rect"
            x="325.934"
            y="588.096"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 325.934 588.096)"
            fill="#AB2EFF"
          />
          <rect
            className="rect"
            x="1137.55"
            y="105"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 1137.55 105)"
            fill="#ED7902"
          />
          <rect
            className="rect"
            x="1130.45"
            y="346.548"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 1130.45 346.548)"
            fill="#FDBD10"
          />
          <rect
            className="rect"
            x="1291.48"
            y="24"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 1291.48 24)"
            fill="#AB2EFF"
          />
          <rect
            className="rect"
            x="888.902"
            y="24"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 888.902 24)"
            fill="#2356F6"
          />
          <rect
            className="rect"
            x="970.387"
            y="24"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 970.387 24)"
            fill="#EC1C24"
          />
          <rect
            className="rect"
            x="729.387"
            y="24"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 729.387 24)"
            fill="#EC1C24"
          />
          <rect
            className="rect"
            x="808.387"
            y="668.613"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 808.387 668.613)"
            fill="#2356F6"
          />
          <rect
            className="rect"
            x="970.387"
            y="588"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 970.387 588)"
            fill="#2356F6"
          />
          <rect
            className="rect"
            x="1378.39"
            y="507.581"
            width="60.3871"
            height="60.3871"
            rx="30.1935"
            transform="rotate(90 1378.39 507.581)"
            fill="#FDBD10"
          />
          <path
            className="path"
            d="M619.5 54L619.5 134"
            stroke="#FDBD10"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1348 377L1348 457"
            stroke="#AB2EFF"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1100 54L1020 54"
            stroke="#FDBD10"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M376 54L296 54"
            stroke="#FDBD10"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M538 135L459 135C458.448 135 458 134.552 458 134L458 53"
            stroke="#2356F6"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1106 457L1027 457C1026.45 457 1026 456.552 1026 456L1026 375"
            stroke="#AB2EFF"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M945 698L866 698C865.448 698 865 697.552 865 697L865 616"
            stroke="#FDBD10"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M701 698L701 619C701 618.448 701.448 618 702 618L783 618"
            stroke="#AB2EFF"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1266 135L1187 135C1186.45 135 1186 134.552 1186 134L1186 53"
            stroke="#2356F6"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M866 134H1025C1025.55 134 1026 134.448 1026 135V216"
            stroke="#AB2EFF"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M782 55L782 134C782 134.552 781.552 135 781 135L700 135"
            stroke="#ED7902"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M619 619L619 698C619 698.552 618.552 699 618 699L457 699"
            stroke="#EC1C24"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1348 619L1348 698C1348 698.552 1347.55 699 1347 699L1186 699"
            stroke="#EC1C24"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1267 458L1267 617C1267 617.552 1266.55 618 1266 618L1101 618C1100.45 618 1100 617.552 1100 617L1100 539C1100 538.448 1099.55 538 1099 538L1027 538C1026.45 538 1026 538.448 1026 539L1026 618"
            stroke="#2356F6"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1263 297L1263 376C1263 376.552 1262.55 377 1262 377L1181 377"
            stroke="#ED7902"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M216 216C216 216 263.74 216 295.011 216C295.563 216 296 215.552 296 215L296 137C296 136.448 295.552 136 295 136L133 136"
            stroke="#AB2EFF"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M376 296L135 296"
            stroke="#2356F6"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M376 136L376 216"
            stroke="#EC1C24"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1181 538V458"
            stroke="#EC1C24"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1024 699H1104"
            stroke="#EC1C24"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1101 216H1261"
            stroke="#EC1C24"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1348 296V54"
            stroke="#FDBD10"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M1027 296H1187"
            stroke="#2356F6"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M135 698L135 538"
            stroke="#FDBD10"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M54 458V618"
            stroke="#AB2EFF"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M134 216L54 216"
            stroke="#ED7902"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M54 54V296"
            stroke="#ED7902"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M295 699H215"
            stroke="#EC1C24"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M215 458H135"
            stroke="#ED7902"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M215 536L215 377C215 376.448 215.448 376 216 376L297 376"
            stroke="#ED7902"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M376 538L296 538"
            stroke="#2356F6"
            strokeWidth="60"
            strokeLinecap="round"
          />
          <path
            className="path"
            d="M537 619L377 619C376.448 619 376 618.552 376 618L376 457"
            stroke="#2356F6"
            strokeWidth="60"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export { Login };
