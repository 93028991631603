import { ReactNode } from 'react';

type ColTypes = { from: number; to: number };
interface GridColProps {
    // from?: number;
    // to?: number;
    sm?: ColTypes;
    md?: ColTypes;
    lg?: ColTypes;
    children?: ReactNode | ReactNode[];
}

const GridCol = ({ sm, md, lg, children }: GridColProps) => {
    const colFromSm = sm && sm.from;
    const colToSm = sm && sm.to;
    const colFromMd = md && md.from;
    const colToMd = md && md.to;
    const colFromLg = lg && lg.from;
    const colToLg = lg && lg.to;

    return (
        <div
            className="grid__col flow"
            style={
                {
                    '--jsx-sm-col-from': colFromSm,
                    '--jsx-sm-col-to': colToSm,
                    '--jsx-md-col-from': colFromMd,
                    '--jsx-md-col-to': colToMd,
                    '--jsx-lg-col-from': colFromLg,
                    '--jsx-lg-col-to': colToLg
                } as React.CSSProperties
            }>
            {children}
        </div>
    );
};

// const GridCol = ({ from, to, children }: GridColProps) => {
//     const colFrom = from ? from : 0;
//     const colTo = to ? to : -1;
//     return (
//         <div
//             className="grid__col flow"
//             style={
//                 {
//                     '--grid-col-from': colFrom,
//                     '--grid-col-to': colTo
//                 } as React.CSSProperties
//             }>
//             {children}
//         </div>
//     );
// };

export { GridCol };
