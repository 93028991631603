import { Link } from 'react-router-dom';
import { IconChevronRight } from '../Icon/IconChevronRight';
import { IconG } from '../Icon';

type Crumb = {
  route: string;
  title: string;
};

interface BreadcrumbProps {
  crumbs?: Crumb[];
}

const Breadcrumbs = ({ crumbs }: BreadcrumbProps) => {
  return (
    <nav className="crumbs">
      <ul>
        <>
          <li>
            <Link to="/">Dashboard</Link>
            <IconG name="chevron-right" size="small" />
          </li>
          {crumbs.map((crumb, i, row) => {
            if (i + 1 === row.length) {
              return (
                <li key={i}>
                  <Link to={crumb.route}>{crumb.title}</Link>
                </li>
              );
            } else {
              return (
                <li key={i}>
                  <Link to={crumb.route}>{crumb.title}</Link>
                  <IconG name="chevron-right" size="small" />
                </li>
              );
            }
          })}
        </>
      </ul>
    </nav>
  );
};

export { Breadcrumbs };
