import React, { InputHTMLAttributes } from 'react';
import { IconAlert, IconTick } from '../Icon';

export type ValidationType = 'alert' | 'success';
export interface ValidationProps {
  type: ValidationType;
  message: string | string[];
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelHidden?: boolean;
  name: string;
  helper?: string;
  handleChange?: any;
  validation?: ValidationProps;
}

const Input = ({
  label,
  labelHidden,
  name,
  helper,
  handleChange,
  validation,
  ...attrs
}: InputProps) => {
  return (
    <div className="input">
      <label
        className={`input__label ${labelHidden && ' visually-hidden'}`}
        htmlFor={'input-' + name}>
        {label}
      </label>
      <input
        id={'input-' + name}
        aria-labelledby={helper ? helper : null}
        type={attrs.type ? attrs.type : 'text'}
        onChange={handleChange}
        {...attrs}
      />
      {validation && (
        <div className="input__validation">
          {validation.type === 'alert' && <IconAlert size="small" />}
          {validation.type === 'success' && <IconTick size="small" />}
          {validation.message}
        </div>
      )}
      {helper && <div className="input__helper">{helper}</div>}
    </div>
  );
};

export { Input };
