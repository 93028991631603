import React, { useCallback, useState, useId, MouseEventHandler } from 'react';
import { TaskSummary, TaskSummaryProps } from './TaskSummary';
import { IconG } from '../Icon';
import { Loader } from '../Loader';
interface TaskTableProps {
  tasks: any[]; // TODO: workout why this didn't like object[] :(
  loading: any;
}

type Data = any[];
type SortKeys = 'rank' | 'completionRate' | 'satisfaction';
type SortOrder = 'asc' | 'desc';
type Selected = boolean;

function sortData({
  tableData,
  sortKey,
  reverse
}: {
  tableData: Data;
  sortKey: SortKeys;
  reverse: boolean;
}) {
  if (!sortKey) return tableData;

  const sortedData = tableData.sort((a, b) => {
    return a[sortKey] > b[sortKey] ? 1 : -1;
  });

  if (reverse) {
    return sortedData.reverse();
  }
  return sortedData;
}

function SortButton({
  sortOrder,
  columnKey,
  sortKey,
  children,
  onClick
}: {
  sortOrder: SortOrder;
  columnKey: SortKeys;
  sortKey: SortKeys;
  children: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <button
      className={`task-table__title ${
        sortKey === columnKey && sortOrder === 'desc' ? 'sorted-reverse' : ''
      } ${sortKey === columnKey ? 'task-active' : ''}`}
      onClick={onClick}>
      {children}
    </button>
  );
}

const TaskTable = ({ tasks, loading }: TaskTableProps) => {
  const [sortKey, setSortKey] = useState<SortKeys>('rank'); // null
  const [sortOrder, setSortOrder] = useState<SortOrder>('asc');

  const headers: { key: SortKeys; label: string; active: Selected }[] = [
    { key: 'rank', label: 'Task', active: false },
    { key: 'completionRate', label: 'Completion rate', active: false },
    { key: 'satisfaction', label: 'Customer Satisfaction', active: false }
  ];

  const sortedData = useCallback(
    () =>
      sortData({ tableData: tasks, sortKey, reverse: sortOrder === 'desc' }),
    [tasks, sortKey, sortOrder]
  );

  function changeSort(key: SortKeys) {
    if (key === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('desc');
    }
  }

  function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  const totalCount = tasks.reduce((previous, current) => {
    return previous + current.responseCount;
  }, 0);

  const tId = useId();

  return (
    <div className="task-table flow-s">
      <ul className="task-table__titles">
        {headers.map((row) => {
          return (
            <li key={row.key} className="task-table__col">
              <SortButton
                columnKey={row.key}
                onClick={() => changeSort(row.key)}
                {...{
                  sortOrder,
                  sortKey
                }}>
                <span>{row.label}</span>
                {sortKey === row.key && sortOrder === 'asc' && (
                  <IconG name="sort-up" size="small" />
                )}
                {sortKey === row.key && sortOrder === 'desc' && (
                  <IconG name="sort-down" size="small" />
                )}
                {sortKey !== row.key && <IconG name="sort-rest" size="small" />}
              </SortButton>
            </li>
          );
        })}
      </ul>

      <ul className="task-table__list">
        {loading ? (
          <li className="task-table__loading">
            <Loader loading={tasks} />
          </li>
        ) : null}

        {tasks.length
          ? sortedData().map((task: TaskSummaryProps, i) => {
              const percentCount = round(
                (task.responseCount / totalCount) * 100,
                0
              );
              return (
                <li key={tId + i}>
                  <TaskSummary {...task} percentage={percentCount} />
                </li>
              );
            })
          : !loading && (
              <li className="task-table__no-data">
                <h2 className="p-500-hvy">No data found</h2>
                <p className="p-400-rg mono-grey">
                  please select a different filter
                </p>
              </li>
            )}
      </ul>
    </div>
  );
};

export { TaskTable };
