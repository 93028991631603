import { ReactNode } from 'react';
interface SectionProps {
    title?: string;
    intro?: string;
    children?: ReactNode;
}

const SectionHead = ({ title, intro, children }: SectionProps) => {
    return (
        <header className="section__head">
            <div className="section__head-inner container">
                <div className="section__heading-wrap">
                    <h2 className="section__title">{title}</h2>
                    <p className="section__intro">{intro}</p>
                </div>
                <div className="section__utility">{children}</div>
            </div>
        </header>
    );
};

export { SectionHead };
