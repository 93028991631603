import { ReactNode, useState } from 'react';
import { motion } from 'framer-motion';
import bem from '../../utilities/bem';

interface TabProps {
    open: boolean | string;
    label: string;
    ariaRef: string;
    children?: ReactNode;
    onClick?: (data) => void;
}

const Tab = ({ label, ariaRef, children, open, onClick }: TabProps) => {
    const tabClick = () => {
        onClick({ label, open });
    };

    const spring = {
        type: 'spring',
        stiffness: 1000,
        damping: 50
    };

    return (
        <button
            className={bem('tab', [open && 'open'])}
            onClick={tabClick}
            role="tab"
            aria-selected={open ? 'true' : 'false'}
            aria-controls={ariaRef}>
            {children}
            {label}
            {open && (
                <motion.div
                    layoutId="tab__indicator"
                    className="tab__indicator"
                    initial={false}
                    transition={spring}></motion.div>
            )}
        </button>
    );
};

export { Tab };
