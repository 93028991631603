import { ReactNode } from 'react';
import { LayoutGroup } from 'framer-motion';

interface TabsProps {
    children: ReactNode[];
}

const Tabs = ({ children }: TabsProps) => {
    return (
        <LayoutGroup>
            <nav className="tabs punch-out">
                <ul className="tabs__container">
                    {children.map((child: ReactNode, index) => {
                        return <li key={index}>{child}</li>;
                    })}
                </ul>
            </nav>
        </LayoutGroup>
    );
};

export { Tabs };
