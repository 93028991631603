import { Icon, IconSVGProps } from './Icon';

const IconTick = ({ size }: IconSVGProps) => {
    return (
        <Icon size={size}>
            <svg
                role="img"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.6673 3.45301L7.06065 11.0663L4.23398 8.23967L5.17398 7.29967L7.06065 9.18634L13.7273 2.51967L14.6673 3.45301ZM13.194 6.81301C13.2807 7.19301 13.334 7.59301 13.334 7.99967C13.334 10.9463 10.9473 13.333 8.00065 13.333C5.05398 13.333 2.66732 10.9463 2.66732 7.99967C2.66732 5.05301 5.05398 2.66634 8.00065 2.66634C9.05398 2.66634 10.0273 2.97301 10.854 3.49967L11.814 2.53967C10.734 1.77967 9.42065 1.33301 8.00065 1.33301C4.32065 1.33301 1.33398 4.31967 1.33398 7.99967C1.33398 11.6797 4.32065 14.6663 8.00065 14.6663C11.6807 14.6663 14.6673 11.6797 14.6673 7.99967C14.6673 7.20634 14.5207 6.44634 14.2673 5.73967L13.194 6.81301Z"
                    fill="CurrentColor"
                />
            </svg>
        </Icon>
    );
};

export { IconTick };
