import * as UiSelect from '@radix-ui/react-select';
import { IconG } from '../Icon/';

type SelectOptions = {
  value: string;
  text: string;
};

interface SelectProps {
  label: string;
  options: SelectOptions[];
  onValueChange?: any;
  value?: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  options,
  onValueChange,
  value
}: SelectProps) => {
  return (
    <UiSelect.Root onValueChange={onValueChange} value={value}>
      <UiSelect.Trigger
        className="select button button--large button--secondary"
        aria-label={label}>
        <IconG name="filter" size="small" />
        <UiSelect.Value placeholder={options[0].text} />
        <UiSelect.Icon>
          <IconG name="chevron-down" size="small" />
        </UiSelect.Icon>
      </UiSelect.Trigger>
      <UiSelect.Portal>
        <UiSelect.Content position="popper" className="select__content">
          <UiSelect.Viewport className="select__viewport">
            <UiSelect.Group>
              <UiSelect.Label className="select__label">{label}</UiSelect.Label>

              {options.map((item, i) => {
                return (
                  <UiSelect.Item
                    key={item.value + '-' + i}
                    className="select__item"
                    value={item.value}>
                    <UiSelect.ItemText>{item.text}</UiSelect.ItemText>
                  </UiSelect.Item>
                );
              })}
            </UiSelect.Group>
          </UiSelect.Viewport>
        </UiSelect.Content>
      </UiSelect.Portal>
    </UiSelect.Root>
  );
};

export { Select };
