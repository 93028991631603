import { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Header } from './components/Header';
import { Button } from './components/Button';
import { Login } from './components/Login';
import * as Popover from '@radix-ui/react-popover';
import './sass/styles.scss';
import { Input } from './components/Input';
import AppContext from './hooks/appContext';
import { supabase } from './data/api';
import { Avatar } from './components/Avatar';

function App() {
  const [LoggedIn, setLoggedIn] = useState(null);
  const [magiclinkSent, setMagiclinkSent] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');

  const [loading, setLoading] = useState(false);

  const context = {
  };

  const handleLogin = async () => {
    if (!email) return;

    setLoading(true);
    const { error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        emailRedirectTo: window.location.href
      }
    });
    setLoading(false);
    setMagiclinkSent(true);
    if (error) console.error(error.message);
  };

  useEffect(() => {
    const hash = window.location.hash.substr(1); // Remove the '#' character
    const params = new URLSearchParams(hash);
    
    // if the page has loaded from a magic link and it's not a success show the code entry
    if (params.has('error') && params.get('error') === 'access_denied') {
        setMagiclinkSent(true);
    }

    supabase.auth.getSession().then(({ data, error }) => {

      //console.log(222, data, error);
      if (data && data.session) {
        setLoggedIn(true);
      } else {
        setLoggedIn(false);
      }
      if (error) console.error(error.message);
    });
  }, []);

  // TODO: how do we log out?
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    setLoggedIn(false);
    if (error) console.error(error.message);
  };

  return (
    <div className="App">
      <AppContext.Provider value={context}>
        {LoggedIn && (
          <>
            <Header>
              <div className="flex-row">
                <Popover.Root>
                  <Popover.Trigger className="button-reset">
                    <Avatar />
                  </Popover.Trigger>

                  <Popover.Portal>
                    <Popover.Content
                      className="popover__content"
                      side="bottom"
                      align="end">
                      <ul className="popover__list">
                        <li>
                          <Link
                            to="/"
                            className="popover__link"
                            onClick={() => handleLogout()}>
                            Log out
                          </Link>
                        </li>
                      </ul>
                    </Popover.Content>
                  </Popover.Portal>
                </Popover.Root>
              </div>
            </Header>
          </>
        )}

        <main className={`main ${!LoggedIn && 'main--login-screen'}`}>
          {LoggedIn!==null && !LoggedIn && (
            <Login
              title="Sign in to Intentful"
              success={magiclinkSent ? 'A verification code has been sent to your email address' : null}
              intro={
                magiclinkSent
                  ? 'Please enter the code below, or send a new code'
                  : 'Enter your email address and we’ll send you a code to get access'
              }>
              {!magiclinkSent && (
                <>
                <div className="login-screen__input-wrap ">
                  <Input
                    label="Email address"

                    labelHidden={true}
                    name="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                </div>
                <Button
                size="max"
                color="primary"
                
                onClick={handleLogin}>
                {loading ? (
                  <span className="button-loader"></span>
                ) : (
                  'Send code'
                )}
              </Button></>
              )}
              {magiclinkSent && (
              <>
              <div className="login-screen__input-wrap ">
                <Input
                    label="Code"
                    labelHidden={true}
                    name="email"
                    placeholder="Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    required
                  />
                <Button
                size="max"
                color="primary"
                onClick={async () => {
                  const { error } = await await supabase.auth.verifyOtp({ email, token:code, type: 'email'})
                  if (error) console.error(error.message);
                  setLoggedIn(true);
                }}>Sign in</Button>
                <a className="cancel_button" href="#" onClick={() => setMagiclinkSent(false)}>Cancel</a>
                </div>
              </>
              )}
            </Login>
          )}
          {LoggedIn && <Outlet />}
        </main>
      </AppContext.Provider>
    </div>
  );
}

export default App;
