import bem from '../../utilities/bem';

type Size = 'small' | 'medium' | 'large';
interface IconProps {
    size?: Size;
    children: JSX.Element;
}

export interface IconSVGProps {
    size?: Size;
}

const Icon = ({ size, children }: IconProps) => {
    return <div className={bem('icon', size)}>{children}</div>;
};

export { Icon };
