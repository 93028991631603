import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/Button';
import { Section, SectionHead } from '../components/Section';

interface ErrorProps {
    title: string;
}

const Error = ({ title }: ErrorProps) => {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate('/');
    };
    return (
        <AnimatePresence mode="wait">
            <motion.section
                className="error-page"
                key="error"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, type: 'tween' }}>
                <div className="container flow flow-m-l">
                    <Section>
                        <SectionHead title={title}></SectionHead>
                        <Button onClick={handleClick}>Run home</Button>
                    </Section>
                </div>
            </motion.section>
        </AnimatePresence>
    );
};

export { Error };
