import { useState } from 'react';
import { Breadcrumbs } from '../components/Breadcrumbs';
import { Tabs, Tab, TabContent } from '../components/Tabs';
import { Section, SectionHead } from '../components/Section';
import { Question } from '../components/Question';
import { IconList, IconVisible } from '../components/Icon';
import { AnimatePresence, motion } from 'framer-motion';
import { Grid, GridCol } from '../components/Grid';

// fake data
import { getSurveySetup } from '../data/surveySetupData';
import { SurveySetupInterface } from '../data/ApiTypes';

const SurveyNew = () => {
    const surveySetup = getSurveySetup() as SurveySetupInterface[];
    const [tabData, setTabData] = useState({
        label: 'Questions'
    });

    const handleTab = (tab) => {
        const tabObject = {
            ...tab,
            open: true
        };
        setTabData(tabObject);
    };

    const crumb = [
        {
            route: '/survey-new/',
            title: 'New survey'
        }
    ];

    return (
        <AnimatePresence mode="wait">
            <motion.section
                className="survey-dashboard"
                key="survey-new"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3, type: 'tween' }}>
                <div className="container flow flow-m-l">
                    <Breadcrumbs crumbs={crumb} />
                    <Section>
                        <SectionHead title={'New survey'}></SectionHead>
                        <Tabs>
                            <Tab
                                label="Questions"
                                ariaRef="qTc"
                                open={'Questions' === tabData.label}
                                onClick={(data) => handleTab(data)}>
                                <IconList size="medium" />
                            </Tab>
                            <Tab
                                label="Targeting"
                                ariaRef="tTc"
                                open={'Targeting' === tabData.label}
                                onClick={(data) => handleTab(data)}>
                                <IconVisible size="medium" />
                            </Tab>
                        </Tabs>
                        <TabContent
                            ariaId="qTc"
                            open={'Questions' === tabData?.label || !tabData}>
                            <Grid>
                                <GridCol
                                    md={{ from: 3, to: 11 }}
                                    lg={{ from: 4, to: 10 }}>
                                    {surveySetup.map((question, i) => {
                                        return (
                                            <Question
                                                key={question.id}
                                                {...question}
                                            />
                                        );
                                    })}
                                </GridCol>
                            </Grid>
                        </TabContent>
                        <TabContent
                            ariaId="tTc"
                            open={'Targeting' === tabData?.label || !tabData}>
                            <div className="flex-row">
                                <p className="text-verticle">
                                    参ろけなぼ面於連ごフレ灯謙善ゃげよ図厚ば件開の如8国覧ニメ坂発イオロヌ権行スねご医講ニキエ麩浩べいンぎ協権ス渡理ソヲ民
                                </p>
                                <p className="text-verticle">
                                    便メモ景開ヌテシミ朱断社クれ
                                </p>
                                <p className="text-verticle">
                                    細減セヌル全案惑表ばてスげ情投ほはレク授情ケルセ顔辞ヤ混
                                </p>
                                <p className="text-verticle">
                                    台ホ才載情びや中成び化覚理ソナクサ解考リラチニ区弾女の
                                </p>
                            </div>
                        </TabContent>
                    </Section>
                </div>
            </motion.section>
        </AnimatePresence>
    );
};

export { SurveyNew };
