import { Icon, IconSVGProps } from './Icon';

const IconAdd = ({ size }: IconSVGProps) => {
    return (
        <Icon size={size}>
            <svg
                role="img"
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M32.1667 21.6667H22.1667V31.6667H18.8333V21.6667H8.83334V18.3333H18.8333V8.33333H22.1667V18.3333H32.1667V21.6667Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export { IconAdd };
