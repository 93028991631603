import { Icon, IconSVGProps } from './Icon';

const IconWallet = ({ size }: IconSVGProps) => {
    return (
        <Icon size={size}>
            <svg
                role="img"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.334 2.66699H2.66732C1.92732 2.66699 1.34065 3.26033 1.34065 4.00033L1.33398 12.0003C1.33398 12.7403 1.92732 13.3337 2.66732 13.3337H13.334C14.074 13.3337 14.6673 12.7403 14.6673 12.0003V4.00033C14.6673 3.26033 14.074 2.66699 13.334 2.66699ZM13.334 12.0003H2.66732V8.00033H13.334V12.0003ZM13.334 5.33366H2.66732V4.00033H13.334V5.33366Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export { IconWallet };
