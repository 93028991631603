import { Icon, IconSVGProps } from './Icon';

const IconList = ({ size }: IconSVGProps) => {
    return (
        <Icon size={size}>
            <svg
                role="img"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                    x="2"
                    y="3.33301"
                    width="10.6667"
                    height="1.33333"
                    fill="currentColor"
                />
                <rect
                    x="3.33398"
                    y="7.33301"
                    width="10.6667"
                    height="1.33333"
                    fill="currentColor"
                />
                <rect
                    x="2"
                    y="11.333"
                    width="10.6667"
                    height="1.33333"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export { IconList };
