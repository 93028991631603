import { ReactNode } from 'react';
interface SectionProps {
  children?: ReactNode;
}

const Section = ({ children }: SectionProps) => {
  return (
    <section className="section">
      <div className="container flow">{children}</div>
    </section>
  );
};

export { Section };
