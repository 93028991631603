let surveySetupData = [
    {
        id: 1,
        overline: 'Introduction',
        title: 'What is the reason for your visit today?'
    },
    {
        id: 2,
        overline: 'Tasks',
        title: 'What were you trying to do?',
        helper: 'Choose what best describes your visit today',
        tasklist: {
            title: 'List of tasks',
            helper: 'Tasks are presented in randomised order',
            tasks: [
                {
                    id: 1,
                    label: 'task one',
                    placeholder:
                        'Board resources (best practice guides and templates)'
                },
                {
                    id: 2,
                    label: 'task two',
                    placeholder:
                        'Become a director (advice, programmes, mentoring)'
                },
                {
                    id: 3,
                    label: 'task three',
                    placeholder: 'Progress my governance career'
                },
                {
                    id: 4,
                    label: 'task four',
                    placeholder: 'Courses (find, book, pay, attend)'
                },
                {
                    id: 5,
                    label: 'task five',
                    placeholder: 'Find a board position'
                },
                {
                    id: 6,
                    label: 'task six',
                    placeholder: 'Board evaluations, training, coaching, advice'
                }
            ]
        }
    },
    {
        id: 3,
        overline: 'Completion',
        title: 'Were you successful?'
    },
    {
        id: 4,
        overline: 'Failure reason',
        title: 'Why not?',
        tasklist: {
            title: 'List of reasons',
            helper: 'Presented in randomised order',
            tasks: [
                {
                    id: 1,
                    label: 'task one',
                    placeholder: 'Could not find what I was looking for'
                },
                {
                    id: 2,
                    label: 'task two',
                    placeholder: 'Encountered a technical error'
                }
            ]
        }
    },
    {
        id: 5,
        overline: 'Cost of failure',
        title: 'What will you do instead?'
    },
    {
        id: 6,
        overline: 'Customer satisfaction',
        title: 'How was your experience?'
    },
    {
        id: 7,
        overline: 'Segmentation',
        title: 'What best describes you?',
        tasklist: {
            title: 'List of options',
            helper: 'Presented in randomised order',
            tasks: [
                {
                    id: 1,
                    label: 'task one',
                    placeholder: 'Farmer'
                },
                {
                    id: 2,
                    label: 'task two',
                    placeholder: 'Developer'
                }
            ]
        }
    },
    {
        id: 8,
        overline: 'Segmentation',
        title: 'How often do you use the Mercury [channel]?'
    },
    {
        id: 9,
        overline: 'Finish',
        title: 'Thank you for your feedback'
    }
];

export function getSurveySetup() {
    return surveySetupData;
}
