import { useState } from 'react';
import { Button } from '../Button';
import { IconAdd } from '../Icon';
import { motion } from 'framer-motion';
import { ApiTaskListInterface, ApiTaskInterface } from '../../data/ApiTypes';
import { AddTask } from '../AddTask/AddTask';

interface AddTaskListProps {
  tasklist: ApiTaskListInterface;
}

const AddTaskList = ({ tasklist }: AddTaskListProps) => {
  const [list, setList] = useState(tasklist.tasks);
  const [taskCount, setTaskCount] = useState(tasklist.tasks.length + 1);

  function handleAdd(count) {
    const newList = list.concat({
      id: count,
      label: 'task' + count,
      placeholder: 'Add new task label'
    });
    setList(newList);
    setTaskCount(count + 1);
  }

  function handleRemove(id) {
    const newList = list.filter((task: ApiTaskInterface) => task.id !== id);
    setList(newList);
    setTaskCount(id - 1);
  }

  // Motion transtions
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const item = {
    hidden: { opacity: 0, x: -10 },
    show: { opacity: 1, x: 0 }
  };

  return (
    <div className="add-tasklist flow flow-m-s">
      <h2 className="add-tasklist__title">{tasklist.title}</h2>
      <p className="add-tasklist__helper">{tasklist.helper}</p>
      <motion.ul
        className="flow flow-m-s"
        variants={container}
        initial="hidden"
        animate="show">
        {list.map((task: ApiTaskInterface, i) => {
          return (
            <AddTask
              key={task.id}
              task={task}
              variants={item}
              onClick={() => handleRemove(task.id)}
            />
          );
        })}
      </motion.ul>
      {list.length <= 10 ? (
        <Button
          color="secondary"
          size="medium"
          onClick={() => handleAdd(taskCount)}>
          <IconAdd size="medium" />
          Add another task
        </Button>
      ) : (
        <p className="p-400-rg">10 task limit reached</p>
      )}
    </div>
  );
};

export { AddTaskList };
