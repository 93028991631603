import { motion } from 'framer-motion';
import { IconG } from '../Icon';
export interface CardProps {
  id?: number;
  title: string;
  overline?: string;
  onClick?: () => void;
}

const Card = ({ id, title, overline, onClick }: CardProps) => {
  const spring = {
    type: 'spring',
    stiffness: 1000,
    damping: 100
  };

  return (
    <motion.article
      layout
      key={'card-' + id}
      initial={{ opacity: 0, y: 24 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -24 }}
      transition={spring}
      id={'card-' + id}
      className="card"
      onClick={() => onClick()}>
      <div className="card__inner flow flow-xs">
        <IconG name="gl-two" size="medium" />
        <p className="card__overline">{overline}</p>
        <h2 className="card__title">{title}</h2>
      </div>
    </motion.article>
  );
};

export { Card };
