import { useState } from 'react';
import { AddTaskList } from '../AddTaskList';
import { Button } from '../Button';
import { IconChevronDown, IconTick } from '../Icon';
import { ApiTaskListInterface } from '../../data/ApiTypes';
import { Input } from '../Input';
import { ValidationProps } from '../Input/Input';

import bem from '../../utilities/bem';

type QuestionState = 'disabled' | 'edit' | 'saved';
interface QuestionProps {
    overline: string;
    title: string;
    helper?: string;
    tasklist?: ApiTaskListInterface;
    qState?: QuestionState;
}

const Question = ({
    overline,
    title,
    helper,
    tasklist,
    qState
}: QuestionProps) => {
    const [qTitle, setQtitle] = useState(title);
    const [qHelper, setQhelper] = useState(helper);
    const [isQopen, setQopen] = useState(false);
    const [isQstate, setQstate] = useState('disabled');

    const validation: ValidationProps = {
        type: 'alert',
        message: 'Error Error Error'
    };

    const handleOpen = () => {
        setQopen(!isQopen);
    };

    const handleSave = () => {
        setQopen(!isQopen);
        setQstate('saved');
        // post to server
    };
    return (
        <div className={bem('question', [isQopen ? 'open' : '', isQstate])}>
            <div className="question__head">
                <p className="question__overline">{overline}</p>
                <div className="flex-row">
                    {isQstate === 'saved' && <IconTick size="small" />}
                    <button className="question__toggle" onClick={handleOpen}>
                        <IconChevronDown size="medium" />
                    </button>
                </div>
            </div>

            {!isQopen && <h2 className="question__title">{qTitle}</h2>}
            {isQopen && (
                <div className="question__open flow flow-s-m">
                    <Input
                        label="Question"
                        name="question"
                        placeholder={qTitle}
                        value={qTitle}
                        onChange={(e) => setQtitle(e.target.value)}
                        validation={validation}
                    />
                    {helper && (
                        <Input
                            label="Helper text"
                            name="helper"
                            placeholder={qHelper}
                            value={qHelper}
                            onChange={(e) => setQhelper(e.target.value)}
                        />
                    )}

                    {tasklist && (
                        <div className="question__items">
                            <AddTaskList tasklist={tasklist} />
                        </div>
                    )}

                    <Button size="medium" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            )}
        </div>
    );
};

export { Question };
