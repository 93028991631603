import { Icon, IconSVGProps } from './Icon';

const IconPaint = ({ size }: IconSVGProps) => {
    return (
        <Icon size={size}>
            <svg
                role="img"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M11.0407 5.96L5.08065 0L4.14065 0.94L5.72732 2.52667L2.29398 5.96C1.90065 6.35333 1.90065 6.98667 2.29398 7.37333L5.96065 11.04C6.15398 11.2333 6.41398 11.3333 6.66732 11.3333C6.92065 11.3333 7.18065 11.2333 7.37398 11.04L11.0407 7.37333C11.434 6.98667 11.434 6.35333 11.0407 5.96ZM3.47398 6.66667L6.66732 3.47333L9.86065 6.66667H3.47398ZM12.6673 7.66667C12.6673 7.66667 11.334 9.11333 11.334 10C11.334 10.7333 11.934 11.3333 12.6673 11.3333C13.4007 11.3333 14.0007 10.7333 14.0007 10C14.0007 9.11333 12.6673 7.66667 12.6673 7.66667ZM1.33398 13.3333H14.6673V16H1.33398V13.3333Z"
                    fill="currentColor"
                />
            </svg>
        </Icon>
    );
};

export { IconPaint };
